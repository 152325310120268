.name_span {
  font-size: 12px;
}

.newUser_container {
  /* border-style: solid;
  border-width: 0.5px;
  border-color: rgba(241, 241, 241, 0.074); */
  border-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: grey;
  /* box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 0.1px rgb(69, 69, 69),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
  position: relative;
  background-color: #0A0A0A;
}

.crear_usuario {
  min-width: 1rem;
  border-radius: 0.6rem;
}

.unlock_icon {
  max-height: 25px
}

.title-container {
  position: absolute;
  top: -15px;
  left: 30%;
  right: 30%;
  color: rgba(255, 255, 255, 0.454);
  /* background-color: #282c34; */
  border-radius: 1vh;
  padding: 0;
}

.unlock_div {
  border-radius: 5rem;
}

.eye {
  position: absolute;
  left: 1vh;
  top: 1vh;
  z-index: 2;
}

.showHistory {
  cursor: pointer
}

.handlerButton {
  background-color: #181818;
  user-select: none;
}

.handlerButton:hover {
  background-color: rgba(128, 128, 128, 0.079);
  cursor: pointer;
  color: var(--main-color)
}


.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}


.customDarkInput {
  color: white;
  background-color: #181818;
  border-color: transparent;
}

.customDarkInput::placeholder {
  text-align: start;
  border-radius: 10px;
  font-size: 13px;
  color: #707070
}
.customDarkInput:disabled {
  color: white;
  background-color: #181818;
  border-color: transparent;
  outline: transparent;
  opacity: 0.5;
}

.customDarkInput:focus {
  color: white;
  background-color: #181818;
  border-color: transparent;
  outline: transparent;
}

.customDarkInput:hover {
  background-color: #181818;
}

.btn-green {
  background-color: var(--main-color);
}

/* 
  ::-webkit-input-placeholder {
    text-align: center;
  }
  
  :-moz-placeholder {
    text-align: center;
  }
  
  ::-moz-placeholder {
    text-align: center;
  }
  
  :-ms-input-placeholder {
    text-align: center;
  }
  
  ::placeholder {
    text-align: center;
  } */