.DashboardContainer {
    text-align: center;
    background-color: #111111;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.DashboardContainerNotLogged {
    text-align: center;
    background-color: #111111;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContainerGral {
    /* display: flex; */
    /* width: 75%; */
    min-height: 100vh;
    justify-content: center;
    color: white;
}

.blue-hover:hover {
    color: rgb(46, 138, 250);
}

.greem-hover:hover {
    color: var(--main-color);
}


.handlerButtonAdminSelected {
    user-select: none;
    background-color: var(--main-color);
    color: black;
}

.rounded-md {
    border-radius: 10px;
}