.quantity_input {
    max-width: 100px;
    padding-left: 0.2rem;
    padding-right: 0.1rem;
}

.name_input {
    border-radius: 2rem;
}

.name_quantity_div {
    align-items: center;
    justify-content: center;
}


.addCoins_container {
    /* position: flex; */
    /* max-width: 65vh;
    min-width: 50vh; */
    /* display: flex; */
    /* flex-direction: column; */
    /* border-style: solid; */
    border-width: 1px;
    background-color: #0A0A0A;
    border-color: rgba(241, 241, 241, 0.05);
    border-radius: 0.5rem;
    /* padding-top: 1rem; */
    color: grey;
    /* box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 0.1px rgb(69, 69, 69),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
    position: relative;

}

.historial_container {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(241, 241, 241, 0.046);
    /* border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; */
    padding: 0.5rem;
    transition: border-color 0.5s ease-in-out;
    /* box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 0.1px rgb(69, 69, 69),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
}

.historial_container:hover {
    background-color: #92929206;

}

.historial_container.success {
    border-color: #28a745;
}

.historial {
    opacity: 0.4;
    border-radius: 0.7rem;
}

.historial:hover {
    opacity: 0.8;
}

.fullhistorial {
    padding: 0rem;
    /* max-height: 100px; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
}



::-webkit-scrollbar-thumb {
    background-color: rgba(241, 241, 241, 0.136);
}

.fullhistorial::-webkit-scrollbar {
    width: 10px;
}

.fullhistorial::-webkit-scrollbar-thumb {
    background-color: #333;
}

.historyHour {
    margin-right: 1vh;
    cursor: pointer;
}

.historyHour:hover {
    color: rgb(255, 255, 255);
}


.subtractButton {
    border-color: #28a745;
}

.dark-dropdown-menu {
    background-color: #343a40;
    /* Fondo oscuro */
    color: #ffffff;
    /* Texto blanco */
}

.dark-dropdown-menu a {
    color: #ffffff;
    /* Enlaces en texto blanco */
}

.dark-dropdown-menu a:hover {
    background-color: grey;
}

.dark-dropdown-custom{
    background-color: #181818;
    color: white;
}