.customRow td {
    border: 0 !important;
    background-color: #181818;
    margin-top: 5px !important;
}

.customRow {
    border-radius: 10px;
}

.customHead {
    border: 0px !important;
    background-color: #181818;
}

.customHead tr {
    border: 0 !important;
    background-color: #181818;
}

.custom-pagination .page-item .page-link {
    color: #ffffff; /* Color del texto */
    background-color: var(--main-color); /* Color de fondo */
    border-color: var(--main-color); /* Color del borde */
}

.custom-pagination .page-item.active .page-link {
    background-color: var(--main-color); /* Color de fondo para el item activo */
    border-color: var(--main-color); /* Color del borde para el item activo */
}

.custom-pagination .page-item .page-link:hover {
    background-color: var(--main-color); /* Color de fondo al pasar el mouse */
    border-color: var(--main-color); /* Color del borde al pasar el mouse */
    color: black; /* Color del texto al pasar el mouse */
}