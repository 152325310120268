.dataHandler_container {
    padding: 1.5rem;
    color: grey;
}

::placeholder {
   text-align: center; 
}

.editbutton{
    cursor: pointer;
}

.editbutton:hover {
    color: var(--main-color)
}