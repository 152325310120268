.hoverCard:hover {
    background-color: #343434;
}

.hoverCard {
    background-color: #272727;
}

.hoverCard_create:hover {
    background-color: #343434;
}

.hoverCard_create {
    background-color: #272727;
}

.btn-green-outline {
    border: 1px solid var(--main-color);
    border-radius: 5px;
    background-color: #181818;
    color: white;
}

.customInputUserCards {
    color: white;
    background-color: #181818;
    border-color: transparent;
    border-radius: 10px;
}

.customInputUserCards::placeholder {
    text-align: start;
    border-radius: 10px;
    font-size: 13px;
    color: #707070
}

.customInputUserCards:focus {
    color: white;
    background-color: #181818;
    border-color: transparent;
    outline: transparent;
}

.customInputUserCards:hover {
    background-color: #181818;
}