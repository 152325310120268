:root {
  --main-color: #00C985;
  /* --main-color: #e100ff; */
}

.App {
  text-align: center;
  font-family: "Fredoka", sans-serif;

}
.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Fredoka", sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-rounded {
  /* border-bottom-left-radius: 5vh;
  border-bottom-right-radius: 5vh; */
  border-radius: 10px;
  background-color: #0A0A0A;
}


.primary-green {
  color: var(--main-color);
}

.border-primary-green {
  border-color: var(--main-color) !important;
}

.btn-success {
  background-color: #00C985 !important;
  color: black;
}


.btn-outline-info {
  border-color: #00C985 !important;
  color: #00C985;
}

.btn-outline-info:hover {
  color: white;
}

.btn-customdark {
  border-color: #00C985;
  color: #00C985;
  background-color: black;
}

.customUltradarkInput {
  color: white;
  background-color: #000000;
  border-color: transparent;
  border-radius: 10px;
}

.customUltradarkInput::placeholder {
  text-align: start;
  border-radius: 10px;
  font-size: 13px;
  color: #000000
}

.customUltradarkInput:focus {
  color: white;
  background-color: #000000;
  border-color: transparent;
  outline: transparent;
}

.customUltradarkInput:hover {
  background-color: #000000;
}


.bg-black {
  background-color: black;
}

.text-green {
  color:  var(--main-color) !important;
}

.swal2-modal {
  background-color: #202020 !important;
  color: white !important;
}

.swal2-confirm {
  background-color: #00C985 !important;
}

.btn-customgray {
  background-color: #181818;
  color: white;
  border-radius: 10px;
}

.customDarkSelect {
  background-color: #181818;
  color: white;
}

.bg-green {
  background-color: #00C985;
}

.modal-content {
  background-color: #202020 !important;
  border: 1px solid #202020 !important;
  color: white;
}

.customDarkDate {
  background-color: #181818;
  color: white;
}

.customDarkDate:focus {
  background-color: #181818;
  color: white;
}

.text-xs {
  font-size: 12px;
}
.spin-animation {
  animation: spin 0.8s linear infinite;
}
