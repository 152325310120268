.copypastes_container {
  /* max-width: 80vh; */
  border-style: solid;
  border-width: 1px;
  border-color: rgba(241, 241, 241, 0.136);
  border-radius: 1rem;
  padding: 2rem;
  color: grey;
  box-shadow:
    inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 0.1px rgb(69, 69, 69),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}



.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.darkBackground {
  background-color: #0A0A0A;
  border-radius: 0.5rem;
}

.btn-customdark {
  border-color: var(--main-color);
  color: var(--main-color);
  background-color: black;
}

.btn-customdark:hover {
  /* background-color: rgb(27, 27, 27); */
  color: white;
}