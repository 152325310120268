.settingsCard {
    background-color: #202020;
    border-radius: 20px;
}


.responsivecard {
    width: 75%;
}

@media (max-width: 768px) {
    .responsivecard {
        width: 100%;
        /* Set width to 100% on mobile devices */
        margin-right: 0;
        /* Remove right margin to prevent overflow */
    }
}

.text-secondary2 {
    color: rgb(168, 168, 168)
}

.cursor-pointer {
    cursor: pointer !important;
}

.selectedTabRight {
    color: black;
    background-color: var(--main-color);
}


.selectedTabLeft {
    background-color: var(--main-color);
    color: black;
}

.settings-tab {
    background-color: #181818;
}

.settings-tab:hover {
    color: white;
}
