.DashboardSupervisor {
    text-align: center;
    background-color: #111111;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 50px;
}

.dark-dropdown-supervisor{
    background-color: #181818;
    color: white;
}

.dark-dropdown-supervisor a:hover{
    background-color: #181818;
}
