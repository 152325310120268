:root {
  --main-color: var(--main-color);
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Fredoka", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: "Fredoka", sans-serif;

}
/* Estilo para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 6px; /* Ancho de la barra de desplazamiento */
}

/* Estilo para el pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #333; /* Color del pulgar */
  border-radius: 3px; /* Borde redondeado del pulgar */
}

/* Cambia el color cuando se está desplazando */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del pulgar al pasar el ratón sobre él */
}

/* Estilo para el fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: #181818; /* Color del fondo de la barra de desplazamiento */
}
