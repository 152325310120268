.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(1px + 0vmin); */
  color: white;
}

.loginFirstStep {
  /* background-image: url('../../assets/clientloginbg.png'); */
  /* background-size: cover; */
  background-color: #111111;
}

@media (max-width:601.98px) {
  .secondRow {
    display: flex;
    flex-direction: row;
  }

}

.sonicgif {
  position: fixed;
  bottom: 0;
  right: 0;
}

.secondRow {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.component_container {
  padding: 0vh;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.my-modal-notifications {
  background-color: #282c34;
  position: absolute;
  margin: auto;
  background-color: rgb(18, 18, 18);
  border-radius: 8px;
  min-width: 400px;
  min-height: 350px;
  padding-top: 20px;
  padding: 5px;
}

.my-modal-notifications-container {
  border: 0;
  padding: 0;
}

.textHoverPrimary {
  color: rgb(207, 207, 207);
  cursor: pointer;
}

.textHoverPrimary:hover {
  color: rgb(78, 78, 188) !important;
}

.userLoginBox {
  background-color: #0A0A0A;
  padding: 5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  /* backdrop-filter: blur(35px); */
  border-radius: 5vh;
  min-width: 500px;
  -webkit-box-shadow: 0px 9px 52px 3px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 9px 52px 3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 9px 52px 3px rgba(0, 0, 0, 0.45);
}

.loginInput::placeholder {
  color: rgba(0, 0, 0, 0.403);
  text-align: start;
}

.loginInput {
  width: 100%;
  border-radius: 10px !important;
  color: black;
  background-color: transparent;
}

.loginInput:focus {
  background-color: transparent;
}


.btnCustomLogin {
  background-color: var(--main-color);
  color: black;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 550;
}

.btnCustomLogin:hover {
  color: black;
  align-items: start;
}

.btnCustomLogin:focus {
  color: black I !important;
  align-items: start;
}

.customDarkInputLogin {
  color: white;
  background-color: #181818;
  border-color: transparent;
  background-color: #000000;
}

.customDarkInputLogin::placeholder {
  text-align: start;
  border-radius: 10px;
  font-size: 13px;
  color: #707070;
}

.customDarkInputLogin:focus {
  color: white;
  background-color: #181818;
  border-color: transparent;
  outline: transparent;
}

.swal2-confirm {
  background-color: var(--main-color) !important;
}