/* Estilo base del switch */
.switch {
    width: 40px;
    height: 18px;
    border-radius: 25px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

/* Estado del switch activado */
.switch-on {
    background-color: var(--main-color);
}

/* Estado del switch desactivado */
.switch-off {
    background-color: #ccc;
}

/* Thumb del switch */
.switch-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    transform: translateX(0);
}

/* Posición del thumb cuando está activado */
.switch-on .switch-thumb {
    transform: translateX(20px);
}